.c-page-hero {
    // Content
    &__content {

        position: absolute;
        z-index: 110;
        top: 50% !important;
        left: 35% !important;
        transform: translate(-50%, -50%) !important;
        bottom: unset !important;
        right: 20%;
        left: 20%;
        padding-bottom: 30px;
        text-align: left;

        // Title
        h2 {
            color: white;
            margin-bottom: 20px;
            font-size: 16px;
        }

        h5 {
            display: none;
        }
        @media (min-width: 1200px) {
            h5 {
                display: inline-block;
                color: white;
                font-size: 19px;
                font-weight: 100;
                line-height: 1.7em;
            }
            h2 {
                font-size: 30px;
            }
        }

        // Container align
        .container {
            display: block;
            margin: 0 auto;
            background: rgb(0, 0, 0);
            background: rgba(0, 0, 0, 0.35);
        }
    }   
}