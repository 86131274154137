.c-dealers{
  margin: 20px 0px;

  .dealer-country-list {
    padding-left: 15px;
  }

  #dealers-holder {
    border-top: 1px solid;
    border-bottom: 1px solid;

    span.attention {
      color: red;
    }

  }

  // Header
  &__header {
    background-color: rgb(44,44,44);
    padding: 10px 20px;
    h4 {
      color: white;
      margin: 0;
    }
  }

  // Content
  &__content {
    overflow: hidden;

    h3 {
      text-align: left;
      transition: 100ms ease all;
      font-weight: normal;
      color: #989c9f;
      font-size: 30px;

      a {
        color: #333;
        text-decoration: none;
        font-size: 16px;

        &:visited {
          text-decoration: none;
          color: #333;
        }
        &:hover {
          text-decoration: none;
          color: #989c9f;
        }
      }
    }

    ul {
      >li {
        margin-left: -15px;
        cursor: pointer;

        &.active {
          a {
            color: #989c9f;
          }
        }

        a {
          color: #333;
          text-decoration: none;
          &:visited {
            text-decoration: none;
            color: #333;
          }
          &:hover {
            text-decoration: none;
            color: #989c9f;
          }
        }
      }
    }
  }

  &__zone {
    margin-top: 20px;
    margin-right: 20px;
    display: inline-block;

    a {
      font-size: 16px;
      font-weight: 500;
      text-decoration: none;
      color: #333;
      cursor: pointer;

      &:visited {
        text-decoration: none;
        color: #333;
      }

      &:active {
        text-decoration: none;
        color: #989c9f;
      }
    }

  }
}