.p-home {
    // Space force
    .c-space-force {
        background-image: url('../img/space-force.jpg');
        background-repeat: no-repeat;
        overflow: hidden;
        background-attachment: fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        -webkit-box-shadow: inset 0px 0px 17px -1px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: inset 0px 0px 17px -1px rgba(0, 0, 0, 0.75);
        box-shadow: inset 0px 0px 17px -1px rgba(0, 0, 0, 0.75);
        background-position-x: -151px;

        @media(max-width: 768px){
            background-color: white;
            background-image: none;
        }
        
        // Content
        &__content {
            max-width: 555px;
            float: right;
            padding-top: 100px;
            padding-bottom: 100px;
            padding-right: 20px;
            padding-left: 20px;
            background: rgb(0, 0, 0);
            background: rgba(0, 0, 0, 0.35);
            color: #ffffff;

            @media(max-width: 768px){
                padding-top: 40px;
                padding-bottom: 60px;
            }

            h2 {
                color: #ffffff;
                margin-bottom: 20px;
            }

            h4 {
                margin-bottom: 20px;
            }
        }
    }

    // Colour force
    .c-colour-force {
        padding: 30px 10px 40px;
        background-color:  rgb(50,50,50);

        // Image
        &__image {
            box-sizing: border-box;
            padding: 5px;
            margin-bottom: 10px;
            img {
                width: 100%;
            }

            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
        }

        h2 {
            color: #8e8e8e;
        }

        h4 {
            color:white;
            font-weight: 100;
            margin-top: 20px;
        }

        p {
            color: white;
            max-width: 1140px;
            display: block;
            margin: 0 auto;
            margin-top: 30px;
            text-align: center;
        }
    }

    // Featured articles
    .c-featured-articles {
        padding: 30px 10px 20px;

        // Featured article item
        &__item {
            img {
                width: 100%;
            }
            margin-bottom: 20px;
        }
    }

    .news-headline {
        margin: 0;
        color: #8e8e8e;
        margin-bottom: 10px;
    }

    .left-arr {
        top: 50%;
        bottom: 50%;
        width: 0;
        left: 0;
    }
    .right-arr {
        top: 50%;
        bottom: 50%;
        width: 0;
        right: 0;
        left: 100%;
    }

    @media (max-width: 1024px){
        .left-arr, .right-arr {
            display: none;
        }
    }

}


