#rental_signup {
  label {
    display: block;
    margin: 0;
    font-weight: bold;
  }
  input, select {
    width: 100%;
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    margin-bottom: 10px;
  }

  #rental_signup_termsAndConditions {
    display: inline-block;
    width: auto;
    margin: 0;
    float: left;
    margin-top: -7px;
    margin-right: 10px;
  }

  label[for=rental_signup_termsAndConditions]{
    display: inline-block;
  }
}

.modal-content {
  border-radius: 0px;
}

.modal-header .close {
  margin-top: 0px;
  color: #ffffff;
  box-shadow: none;
  outline: none;
  text-shadow: none;
}

.modal-header {
  background-color: #262626;
}

.modal-title {
  color: #9d9d9d;
}

.input-error {
  input {
    border-color: red !important;
  }

  label {
    color: red;
  }
}

.modal-body {
  overflow: hidden;

  // Loading
  &__loading {
    position: absolute;
    width: 97%;
    height: 100%;
    background-color: #ffffff9e;
    display: none;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 80px;
    }

    // If active
    &--active {
      display: block;
    }
  }

  // Success
  &__success {
    position: absolute;
    width: 97%;
    height: 100%;
    background-color: #ffffff9e;
    display: none;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 80px;
    }

    // If active
    &--active {
      display: block;
    }
  }
}