.p-category-level-two {

    // Category products
    .c-product-list {
        // Category producut item
        &__item {
            border: 1px solid rgb(44,44,44);
            margin-bottom: 20px;

            // Item header
            &__header {
                background-image: url('../img/hero.jpg');
                background-repeat: no-repeat;
                background-size: cover;
                height: 200px;
                display: table;
                width: 100%;

                // Product title
                h3 {
                    color: white;
                    text-align: center;
                    margin: 0;
                    display: table-cell;
                    vertical-align: middle;
                    font-size: 42px;

                    span {
                        padding: 5px;
                        background: rgba(0, 0, 0, 0.35);
                        display: inline-block;
                    }
                }
            }

            // Item content
            &__content {
                overflow: hidden;
                padding: 40px 0px;

                // Responsive
                @media(max-width: 768px){
                    padding: 20px 0px;
                }

                // Product image
                &__image {
                    padding-left: 25px;

                    // Responsive
                    @media(max-width: 768px){
                        padding-left: 0px;
                    }

                    img {
                        display: block;
                        margin: 0 auto;
                        width: 100%;
                    }
                }

                // Description
                &__description {
                    padding-top: 49px;
                    padding-right: 25px;

                    // Responsive
                    @media(max-width: 768px){
                        padding: 0;
                    }

                    // title
                    h3 {
                        color: #8e8e8e;
                        font-size: 26px;
                        font-weight: bold;

                        a {
                            color: #8e8e8e;
                            text-decoration: none;
                        }
                    }

                    &:hover {
                        h3 {
                            a {
                                color: #8e8e8e;
                            }
                        }
                    }

                    // Description
                    p {
                        font-size: 16px;
                    }

                }

            }
        }
    }
}