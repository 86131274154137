.carousel-caption {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    bottom: unset !important;
}
.carousel {
    img {
        width: 100%;
        max-height: 500px;
    }
}

.carousel-indicators {
    z-index: 6;
}

@media (max-width: 992px) {
    .carousel-indicators {
        display: none;
    }
}