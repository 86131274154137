.c-downloads {
  &__table {
    table {
      width: 100%;
      th {
        background-color: #2c2c2c;
        color: white;
      }
      td, th {
        padding: 10px;
      }
      tr {
        border-bottom: 1px solid grey;
      }
    }
    &__taxon {
      background-color: #989c9f;
      td {
        color: white;
      }
    }
  }

  &__item {
    color: black;
    margin-right: 5px;
  }

  &__mobile-taxon {
    background-color: #989c9f;
    padding: 10px;
    color: #ffffff;
  }


  &__mobile-product {
    padding: 10px;
    cursor: pointer;
  }

  &__hide-for-desktop {
    display: block;
  }
  &__hide-for-mobile {
    display: none;
  }
  @media (min-width: 992px){
    &__hide-for-desktop {
      display: none;
    }
    &__hide-for-mobile {
      display: block;
    }
  }


}