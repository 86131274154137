.c-service {
    padding: 10px;
    margin-bottom: -1px;
  
    // Image
    img {
        width: 100%;
    }

    // Title
    h3 {
        text-align: left;
        transition: 100ms ease all;
        font-weight: bold;
        transition: 200ms ease all;

        a {
            color: black;
            text-decoration: none;
            transition: 200ms ease all;
        }
    }

    // Description
    p {
        text-align: left !important;
        font-size: 16px;
    }

    &:after {
        content: "";
        display: table;
        clear: both;
      }

      &:hover {
          h3 {
            a {
                color: #8e8e8e;
            }
          }
      }
}