.p-product-details {
    padding: 20px 0px;

    .row {
        margin-bottom: 20px;
    }

    &:last-child {
       .row {
            margin-bottom: 0px;
        }
    }

    // Image
    &__image {
        img {
            width: 100%;
            height: auto;
        }
    }

    // Subtittle
    h2 {
        font-weight: 200;
        font-size: 20px;
        margin-top: 0px;
    }

    // Description
    &__description{
        // Product title
        h1 {
            margin-top: 0;

            @media(max-width: 768px) {
                margin-top: 10px;
            }
        }
    }

    // Product video
    &__video {
        margin-top: 20px;
        iframe {
            width: 100%;
            max-height: 200px;
        }
    }

    // Key features
    &__key-features {
        ul {
            padding-left: 16px;
        }
    }

    // Menu
    &__menu {
        margin-top: 20px;
        ul {
            padding: 0;

            li {
                list-style: none;

                a {
                    color: #8e8e8e;
                }
            }
        }
    }

    &__tabs {
        margin-top: 35px;

        img {
            width: 100%;
            height: auto;
        }
    }

    .tab-content {
        margin-top: 30px;
    }

    .btn-tab {
        background-color: #a9adb0;
        color: #ffffff;
        border-radius: 0px;
        font-size: 16px;
        font-weight: bold;
        padding: 10px;
    }

    table {
        border-collapse: collapse;
        width: 100%;

        td, th {
            border: 1px solid #ddd;
            padding: 8px;
        }

        tr:nth-child(even){background-color: #f2f2f2;}

        tr:hover {background-color: #ddd;}

        th {
            padding-top: 12px;
            padding-bottom: 12px;
            text-align: left;
            background-color: #434443;
            color: white;
        }
    }

    #downloads {
        ul {
            list-style-type: none;
            padding-left: 0;

            li {
                background-color: #262626;
                color: white;
                padding: 10px;
                margin-bottom: 10px;
                border-radius: 3px;

                i {
                    margin-right: 3px;
                }

                &:hover {
                    background-color: #484848;
                }

                a {
                    color: white;
                }
            }
        }
    }
}