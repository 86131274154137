.c-breadcrumbs {
    background-color: rgb(152, 156, 159);
    padding: 10px 0px;

    a {
        color: white;

        &:after {
            content: ' > ';
            pointer-events: none;
        }

        &:last-child {
            &:after {
                content: '';
                pointer-events: none;
            }
        }
    }
}