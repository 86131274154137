.sticky th {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 130px;
    background-color: transparent;
    border: none;
}

@media (max-width: 1200px) {
    .sticky th {
        top: 0;
    }
}

