@media (max-width: 1200px) {
    .navbar-header {
        float: none;
    }
    .navbar-left,.navbar-right {
        float: none !important;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        margin-top: 7.5px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapse.in{
        display:block !important;
    }
}

$navbar-height: 0;
$big-navbar-height: 130px;
.c-navbar {
    border-radius: 0;
    min-height: $navbar-height;
    background-color: rgb(38,38,38);
    margin-bottom: 0;
    width: 100%;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.2);
    z-index: 200;

    &__helper {
        height: $navbar-height;
    }

    .glyphicon-search {
        color: white;
    }

    &__search-bar {
        position: fixed;
        width: 100%;
        top: $navbar-height;
        background-color: rgb(50,50,50);
        min-height: 50px;
        z-index: 10;
        display: none;

        i {
            color: #6f7172;
        }

        input {
            width: 100%;
            background-color: transparent;
            outline: none;
            border: none;
            margin: 12px 0;
            color: white;
        }

        &__search-icon {
            display: inline-block;
        }

        &__form {
            display: inline-block;
            width: 85%;
        }

        &__close-search-icon {
            display: inline-block;
            float: right;
            margin: 14px 0;
            cursor: pointer;
            margin-right: 13px;
        }

        &--displayed {
            display: block;
        }
    }

    .navbar-brand {
        height: auto;
        padding: 11px 15px;
    }

    @media (min-width: 1200px){
        position: fixed;
        .navbar-nav > li > a {
            padding-top: 24px;
            padding-bottom: 24px;
            font-size: 15px;
        }
        #myNavbar {
            padding-top: 30px;
        }
        min-height: $big-navbar-height;
        &__helper {
            height: $big-navbar-height;
        }
        &__search-bar {
            top: $big-navbar-height;
        }
    }

    @media (max-width: 1200px){
        // Logo
        &__logo {
            max-width: 127px;
        }
    }

    .navbar-nav > li > a {
        color: #fff4e4;
    }

    .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:focus, .navbar-inverse .navbar-nav > .active > a:hover {
        color: #fff;
        background-color: rgb(50,50,50);
    }

    .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:focus, .navbar-inverse .navbar-nav > .open > a:hover {
        color: #fff;
        background-color: rgb(50,50,50);
    }

    .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:focus, .navbar-inverse .navbar-nav > .open > a{
        color: #fff;
        background-color:  rgb(50,50,50);
    }

    .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:focus, .navbar-inverse .navbar-nav > .open > a:active{
        color: #fff;
        background-color:  rgb(50,50,50);
    }

    .navbar-toggle {
        margin-top: 17px;
        margin-bottom: 17px;
        border: none;
    }

    .social-icons {
        a {
            padding: 10px 10px;
        }

        @media (max-width: 1200px){
            li {
                display: inline !important;
                a {
                    display: inline;
                }
            }
        }
    }

    .navbar-nav > li > .dropdown-menu {
        width: 100%;
        left: 0;
        background-color: #323232;
    }

    .navbar-nav > li > .normal-dropdown {
        position: relative;
        background-color: #323232;
    }

    .dropdown-menu-box, .dropdown-menu {
        padding: 20px 0px;
        box-sizing: border-box;

        a {
            color: white;
        }

        h4 {
            color: #E8E8E8;
            margin: 0;
            margin-bottom: 20px;
        }
    }

    .nav > li {
        display: block;
    }

    @media(min-width: 1200px) {
        .nav > li {
            position: static;
        }
    }

    .navbar-nav {
        display: inline-block;
        float: none;

        @media(max-width: 1200px){
            display: block;

            li > .dropdown-menu {
                position: relative;
            }

        }
    }
    
    .navbar-collapse {
        text-align: center;
        max-height: 69px;

        @media(max-width: 1200px){
            text-align: left;
            max-height: none;
        }
    }

    &__desktop-search-btn {
        @media (max-width: 1200px){
            display: none !important;
        }
    }

    &__mobile-search-btn {
        margin-top: 16px;
        margin-bottom: 14px;
        margin-right: 0;
    }
}

#myNavbar {
    overflow-y: hidden;
}