
.c-ctas {
    background-color:  rgb(50,50,50);
    padding: 20px 0px;
}

.c-cta {
    padding: 10px 0px;
    box-sizing: border-box;
    
    img {
        width: 100%;
        height: auto;
    }
    h3 {
        color: #8e8e8e;

        a {
            color: #8e8e8e;
        }
    }
    p {
        color: white;
    }
}