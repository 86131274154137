.p-discontinued {
    .c-discontinued-item-box  {
        min-height: 410px;
    }
}

.c-discontinued-products{
    margin: 20px 0px;

    // Header
    &__header {
        background-color: rgb(44,44,44);
        padding: 10px 20px;
        h4 {
            color: white;
            margin: 0;
        }
    }

    &__description {
        padding: 20px;
    }

    // Content
    &__content {
        border: 1px solid rgb(44,44,44);
        overflow: hidden;
    }

    .c-discontinued-item-box {
        border: 1px solid rgb(44,44,44);
        padding: 10px;
        margin-bottom: -1px;
        border-left: 1px solid transparent;
        text-align: center;

        &:first-child {
            border-left: 1px solid transparent;
        }

        &:nth-child(3n) {
            border-right: 1px solid transparent;
        }

        @media(max-width: 768px){
            border-right: 1px solid transparent;
        }

        // Image
        img {
            width: 100%;
            height: auto;
        }

        // title
        h3 {
            text-align: center;
            transition: 100ms ease all;
            font-weight: normal;
            width: 100%;

            a {
                color: #333;
                text-decoration: none;
            }
        }

        // Description
        p {
            text-align: center;
            width: 100%;
        }

        &:after {
            content: "";
            display: table;
            clear: both;
        }

        &:hover {
            h3 {
                a {
                    color: #8e8e8e;
                }
            }
        }
    }
}