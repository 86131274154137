.p-category-level-three {
    // Page title
    h1 {
        text-align: center;
        margin-top: 30px;
    }

    // Paragraph
    p {
        text-align: center;
        margin-top: 20px;
    }

    // Products
    &__products {
        margin-top: 30px;
    }

    // Fixed background
    &__fixed-background {
        background-image: url('../img/space-force.jpg');
        background-repeat: no-repeat;
        overflow: hidden;
        background-attachment: fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        -webkit-box-shadow: inset 0px 0px 17px -1px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: inset 0px 0px 17px -1px rgba(0, 0, 0, 0.75);
        box-shadow: inset 0px 0px 17px -1px rgba(0, 0, 0, 0.75);

        // Content
        &__content {
            padding: 100px 0px;
            background: black;
            background: rgba(0, 0, 0, 0.35);
            padding-left: 20px;
            padding-right: 20px;

            @media(max-width: 768px){
                padding: 20px 0px;
            }

            p {
                color: white;;
                text-align: left;
                font-size: 24px;
                font-weight: bold;
                text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
            
                @media(max-width: 768px){
                    font-weight: normal;
                    font-size: 20px;
                }
                
            }
        }

        // Second
        &--second {
            background-image: url('../img/hero.jpg');
            .p-category-level-three__fixed-background__content {
                padding-top: 150px;
                padding-bottom: 50px;

                @media(max-width: 768px){
                    padding: 20px 0px;
                }
            }
        }
    }

    // Services
    &__services {
        padding: 30px 0px;
    }

    .click-for-more-holder {
        width: 100%;
        text-align: center;

        a {
            color: #333;
            &:hover {
                color: #8e8e8e;
                text-decoration: none;
            }
        }

    }

}